.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  white-space: nowrap;
  width: 100%;
  padding: 1rem 0 0;

  a {
    font-size: .8rem;
    color: $global-font-secondary-color;
    letter-spacing: .1rem;
    font-weight: 700;
    padding: 5px 5px;
    text-decoration: none;
    @include transition(0.3s);
  }

  li {
    padding-bottom: 3px;
    margin: 0 20px;
    box-sizing: border-box;
    position: relative;
    display: inline;

    &.disabled {
      display: none;
    }

    &:hover a {
      color: $pagination-link-hover-color;
    }


    &:before,
    &:after {
      position: absolute;
      content: "";
      width: 0;
      height: 3px;
      background: $pagination-link-hover-color;
      @include transition(0.3s);
      bottom: 0px;
    }

    &:before .active,
    &:after .active {
      width: 100%;
    }

    &:before {
      left: 50%;
    }

    &:after {
      right: 50%;
    }

    &:hover {

      &:before,
      &:after {
        width: 50%;
      }
    }

    &.active {
      a {
        color: $pagination-link-hover-color;
      }

      &:before,
      &:after {
        width: 60%;
      }
    }
  }
}
