// ==============================
// Variables
// ==============================

// ========== Global ========== //
// Font and Line Height
$global-font-family: system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$global-font-size: 16px;
$global-font-weight: 400;
$global-line-height: 1.5rem;

// Color of the background
$global-background-color: var(--global-background-color) !default;

// Color of the text
$global-font-color: var(--global-font-color) !default;

// Color of the secondary text
$global-font-secondary-color: var(--global-font-secondary-color) !default;

// Color of the link
$global-link-color: var(--global-link-color) !default;

// Color of the hover link
$global-link-hover-color: var(--global-link-hover-color) !default;

// Color of the border
$global-border-color: var(--global-border-color) !default;
// ========== Global ========== //

// ========== Selection ========== //
// Color of the selected text
$selection-color: var(--selection-color) !default;
// ========== Selection ========== //

// ========== Header ========== //
// Height of the header
$header-height: 3.5rem !default;

// Color of the header background
$header-background-color: var(--header-background-color) !default;

// Font style of the header title
$header-title-font-family: $global-font-family !default;
$header-title-font-size: 1.5rem !default;

// Color of the hover header item
$header-hover-color: var(--header-hover-color) !default;
$header-delimiter: var(--header-delimiter) !default;

// Color of the search background
$search-background-color: var(--search-background-color) !default;
$search-empty-font-color: var(--search-empty-font-color) !default;
// ========== Header ========== //

// ========== Single Content ========== //
// Font weight of the single H2 - H6
$single-h-font-weight: var(--single-h-font-weight) !default;
$single-content-strong-color: var(--single-content-strong-color) !default;
// Font size of the TOC
$toc-title-font-size: 1.2rem !default;
$toc-content-font-size: 1rem !default;

// Color of the single link
$single-link-color: var(--single-link-color) !default;

// Color of the hover single link
$single-link-hover-color: var(--single-link-hover-color) !default;

// Color of the table background
$table-background-color: var(--table-background-color) !default;

// Color of the table thead
$table-thead-color: var(--table-thead-color) !default;
$table-border-color: var(--table-border-color) !default;

// Color of the blockquote
$blockquote-color: var(--blockquote-color) !default;
$blockquote-bg-color: var(--blockquote-bg-color) !default;
// ========== Single Content ========== //

// ========== Pagination ========== //
// Color of the link in pagination
$pagination-link-color: var(--pagination-link-color) !default;

// Color of the hover link in pagination
$pagination-link-hover-color: var(--pagination-link-hover-color) !default;
// ========== Pagination ========== //

// ========== Code ========== //
// Color of the code
$code-color: var(--code-color) !default;

// Color of the code background
$code-background-color: var(--code-background-color) !default;
$code-header-color: var(--code-header-color) !default;
$code-background-color-darken-5: var(--code-background-color-darken-5) !default;
$code-background-color-darken-6: var(--code-background-color-darken-6) !default;
$code-background-color-code-header: var(--code-background-color-code-header) !default;
$code-background-color-code-hl: var(--code-background-color-code-hl) !default;

$code-info-color: var(--code-info-color) !default;

// Font size of the code
$code-font-size: .875rem !default;

// Font family of the code
$code-font-family: Source Code Pro, Menlo, Consolas, Monaco, monospace, $global-font-family !default;

// Code type map
$code-type-map: (
  // Custom code type
  language-bash: 'Bash',
  language-c: 'C',
  language-cs: 'C#',
  language-cpp: 'C++',
  language-clojure: 'Clojure',
  language-coffeescript: 'CoffeeScript',
  language-css: 'CSS',
  language-dart: 'Dart',
  language-diff: 'Diff',
  language-erlang: 'Erlang',
  language-go: 'Go',
  language-go-html-template: 'Go HTML Template',
  language-groovy: 'Groovy',
  language-haskell: 'Haskell',
  language-html: 'HTML',
  language-http: 'HTTP',
  language-xml: 'XML',
  language-java: 'Java',
  language-js: 'JavaScript',
  language-javascript: 'JavaScript',
  language-json: 'JSON',
  language-kotlin: 'Kotlin',
  language-latex: 'LaTeX',
  language-less: 'Less',
  language-lisp: 'Lisp',
  language-lua: 'Lua',
  language-makefile: 'Makefile',
  language-markdown: 'Markdown',
  language-matlab: 'Matlab',
  language-objectivec: 'Objective-C',
  language-php: 'PHP',
  language-perl: 'Perl',
  language-python: 'Python',
  language-r: 'R',
  language-ruby: 'Ruby',
  language-rust: 'Rust',
  language-scala: 'Scala',
  language-scss: 'Scss',
  language-shell: 'Shell',
  language-sql: 'SQL',
  language-swift: 'Swift',
  language-tex: 'TeX',
  language-toml: 'TOML',
  language-ts: 'TypeScript',
  language-typescript: 'TypeScript',
  language-vue: 'Vue',
  language-yml: 'YAML',
  language-yaml: 'YAML',
  ) !default;


// ========== Code ========== //

// ========== Admonition ========== //
// Color map of the admonition
$admonition-color-map: (
  'note': #448aff,
  'abstract': #00b0ff,
  'info': #00b8d4,
  'tip': #00bfa5,
  'success': #00c853,
  'question': #64dd17,
  'warning': #ff9100,
  'failure': #ff5252,
  'danger': #ff1744,
  'bug': #f50057,
  'example': #651fff,
  'quote': #9e9e9e,
  ) !default;

// Color map of the admonition background
$admonition-background-color-map: (
  'note': rgba(68, 138, 255, 0.1),
  'abstract': rgba(0, 176, 255, 0.1),
  'info': rgba(0, 184, 212, 0.1),
  'tip': rgba(0, 191, 165, 0.1),
  'success': rgba(0, 200, 83, 0.1),
  'question': rgba(100, 221, 23, 0.1),
  'warning': rgba(255, 145, 0, 0.1),
  'failure': rgba(255, 82, 82, 0.1),
  'danger': rgba(255, 23, 68, 0.1),
  'bug': rgba(245, 0, 87, 0.1),
  'example': rgba(101, 31, 255, 0.1),
  'quote': rgba(159, 159, 159, 0.1),
  ) !default;
// ========== Friend Link ========== //
// Color of friend link background
$friend-link-background-color: var(--friend-link-background-color) !default;

// Color of friend link text
$friend-link-color: var(--friend-link-color) !default;

// Color of hover friend link text
$friend-link-hover-color: var(--friend-link-hover-color) !default;
// ========== Friend Link ========== //


// ========== Sponsor Button ========== //
// Sponsor button background color
$sponsor-button-background-color: var(--sponsor-button-background-color) !default;

// Sponsor button background color while in the :hover state
$sponsor-button-hover-background-color: var(--sponsor-button-hover-background-color) !default;
// ========== Sponsor Button ========== //

// ========== Cookie Consent ========== //
$cookie-background-color: var(--cookie-background-color) !default;
// ========== Cookie Consent ========== //

// ========= related ========== //
$related-background-color: var(--related-background-color) !default;
$related-color: var(--related-color) !default;
$related-hover-color: var(--related-hover-color) !default;
// ========== related ========== //

// ========== showcase ========== //
$showcase-background-color: var(--showcase-background-color) !default;
$showcase-color: var(--showcase-color) !default;
$showcase-hover-color: var(--showcase-hover-color) !default;
// ========== showcase ========== //